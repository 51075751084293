.space {
  flex: 1 1 auto;
}
.inputsWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.react-daterange-picker-v2 {
  display: inline-flex;
  position: relative;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 50px;
}

.react-daterange-picker-v2__presets {
  margin-left: 189px;
}
.react-daterange-picker-v2__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1500;
}
.react-daterange-picker-v2__calendar .react-calendar {
  border-width: thin;
}
.react-daterange-picker-v2__fullWidth {
  width: 100%;
  max-width: 100%;
}
.react-daterange-picker-v2__disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker-v2__disabled .react-daterange-picker-v2__wrapper {
  cursor: default;
}

.react-daterange-picker-v2,
.react-daterange-picker-v2 *,
.react-daterange-picker-v2 *:before,
.react-daterange-picker-v2 *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-daterange-picker-v2__wrapper {
  padding: 16px;
  display: flex;
  align-items: center;
  border: 1px solid #a3a3af;
  width: 100%;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.react-daterange-picker-v2__inputGroup {
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker-v2__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker-v2__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker-v2__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker-v2__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker-v2__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker-v2__button {
  border: 0;
  background: transparent;
  padding: 0;
  display: block;
}
.react-daterange-picker-v2__button:enabled {
  cursor: pointer;
}
.react-daterange-picker-v2__button:disabled .react-daterange-picker-v2__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker-v2__button svg {
  display: inherit;
}
